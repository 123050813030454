import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import './singlePage.scss'


const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="single-page">
      <div className="flex-wrapper">
        <article>
          <h3>NOT FOUND</h3>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </article>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
